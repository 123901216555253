<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-discussion-forum-96.png"
          class="h-20 ml-2 inline-block"
        />
        صف ها
      </h2>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <div
        v-for="queue in queues"
        :key="queue.id"
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mb-4"
      >
        <div class="flex items-center gap-2">
          <img src="@/assets/images/icons8-discussion-forum-96.png" class="h-10" />
          <h2 class="text-lg font-bold">{{ queue.name }}</h2>
        </div>
        <div class="grid sm:grid-cols-3 gap-2 mt-4">
          <div class="flex p-2">
            <div class="w-full" v-if="queue.active">وضعیت: فعال</div>
            <div class="w-full" v-else>وضعیت: غیر فعال</div>
          </div>
          <div class="flex p-2">
            <div class="w-full">نوع ارسال: {{ lists.sendType[queue.send_type] }}</div>
          </div>
        </div>
        <template v-if="queue.send_type == 'time'">
          <h2 class="text-lg font-bold mt-5">ساعت‌های این صف</h2>
          <div class="grid grid-cols-3 sm:grid-cols-6 lg:grid-cols-8 gap-2 mt-4">
            <div
              v-for="time in queue.times"
              :key="time.id"
              class="border border-blueGray-200 bg-blueGray-50 text-center p-2 rounded-md"
            >
              {{ time.send_time }}
            </div>
          </div>
        </template>
        <div class="flex flex-wrap gap-2">
          <Button
            :to="{
              name: 'ChannelOwnerSettingQueue',
              params: {
                id: $store.state.user.id,
                channelId: channel.id,
                queueId: queue.id,
              },
            }"
            class="mt-3 inline-flex text-lg"
            color="teal"
          >
            ویرایش
          </Button>
          <Button
            :to="{
              name: 'ChannelOwnerSettingPosts',
              params: { id: $store.state.user.id, channelId: channel.id },
              query: {
                branch: 'queue',
                status: 'all',
                queue: queue.id,
              },
            }"
            class="mt-3 inline-flex text-lg"
            color="cyan"
          >
            مشاهده پست ها
          </Button>
        </div>
      </div>
      <Button
        :to="{
          name: 'ChannelOwnerSettingQueueNew',
          params: {
            id: $store.state.user.id,
            channelId: channel.id,
          },
        }"
        class="mt-3 inline-flex text-lg"
        color="teal"
      >
        ایجاد صف جدید
      </Button>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      channel: {},
      queues: [],
      lists: {
        sendType: {
          time: "ساعت های مشخص",
          period: "دوره ای",
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.posts = [];
      $this.$axios
        .get(`api/owner-channels/${$this.$route.params.channelId}/queue`, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          $this.queues = response.data.queues;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>
